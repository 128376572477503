<template>
  <div id="app">
    <div>
      <nav class="attached-nav">
        <router-link
          class="link_new_registration"
          @click="goToNewRegistration"
          to="/visits/new_registration"
          >{{ $t("app.new_registration") }}</router-link
        >
        <router-link to="/accepted/index">{{ $t("app.visits") }}</router-link>
        <router-link v-if="is_finance" to="/documents">{{
          $t("app.documents")
        }}</router-link>
        <router-link to="/supplier">{{ $t("vs.details_label") }}</router-link>
        <a href="#" v-if="loggedin" @click="doLogout">{{ $t("app.quit") }}</a>
        <router-link v-if="!loggedin" to="/supplier/login">{{
          $t("app.login")
        }}</router-link>
        <select id="set_locale" name="locale" @input="setLocale">
          <option
            v-for="(locale, key) in locales"
            :selected="key == current_locale"
            :value="key"
            :key="key"
          >
            {{ locale }}
          </option>
        </select>
        <router-link to="/contact_info">{{ supplier_name }}</router-link>
        <update-app-link />
      </nav>
      <validation-errors
        :message="message"
        :response="errorResponse"
        v-if="errorResponse"
      ></validation-errors>
      <transition name="fade">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import packageJson from "../../package";
import { mapGetters, mapActions } from "vuex";
import ValidationErrors from "../views/ValidationErrors";

export default {
  name: "AppLayout",
  data() {
    return {
      version: packageJson.version,
    };
  },
  components: {
    ValidationErrors,
  },
  computed: {
    ...mapGetters({
      locales: "auth/locales",
      is_finance: "auth/getIsFinancePosition",
      supplier_name: "auth/getSupplierName",
      loggedin: "auth/loggedin",
      current_locale: "auth/locale",
      errorResponse: "error/getErrorResponse",
      message: "error/getMessage",
    }),
  },
  methods: {
    goToNewRegistration() {
      this.$router.push({ name: "new_registration", query: { t: Date.now() } });
    },
    toggle_dom_visit() {
      if (this.$refs.dom_visit) {
        this.$refs.dom_visit.style.display =
          this.$refs.dom_visit.style.display === "none" ? "" : "none";
      }
    },
    ...mapActions({
      setLanguage: "auth/setLanguage",
    }),
    reloadApp() {
      document.location.reload();
    },
    setLocale(e) {
      this.setLanguage(e.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;

  > .white {
    float: right;
    color: $primary-color;
  }
  > a,
  > select {
    float: left;
    display: block;
    color: $primary-color;
    background-color: $black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

    .active {
      background-color: #4caf50;
    }
  }
}
</style>
