/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import apiSignin from "../api/signin";
import {
  AUTH_MUTATION_SET_SUPPORT_PHONE,
  LOGIN,
  LOGOUT,
  INIT_AUTH,
  SET_LANGUAGE,
  SET_IF_UPDATE_FOUND,
  SET_IF_LOGOUT_FOUND,
  SET_COORDS,
} from "../mutation-types";
import packageJson from "../../../package";
import i18n from "@/i18n";
import axios from "axios";
import * as Sentry from "@sentry/vue";

// initial state
// shape: [{ supplier }]
const state = {
  auth: {
    access_token: "",
    refresh_token: "",
    expires_in: "",
  },
  supportEmails: {
    en_ZZ: "info@allsports.fit",
    ru_BY: "suppliers@allsports.by",
    en_BY: "suppliers@allsports.by",
    hy_AM: "info@allsports.am",
    ru_RU: "info@allsports.fit",
    en_UA: "info.ua@allsports.fit",
    uk_UA: "info.ua@allsports.fit",
    en_LT: "sporbenefit.eu@gmail.com",
    lt_LT: "sporbenefit.eu@gmail.com",
    en: "sporbenefit.eu@gmail.com",
  },
  supports: {
    en_ZZ: {
      tel: "",
      tel_title: "",
    },
    ru_BY: {
      tel: "tel:+375445163914",
      tel_title: "+375 (44) 516-39-14",
    },
    en_BY: {
      tel: "tel:+375445163914",
      tel_title: "+375 (44) 516-39-14",
    },
    hy_AM: {
      tel: "tel:+374-91240208",
      tel_title: "+374-91240208",
    },
    ru_RU: {
      tel: "tel:+7-912-042-01-00",
      tel_title: "+7 912 042 01 00",
    },
    en_UA: {
      tel: "tel:+380636205050",
      tel_title: "+380636205050",
    },
    uk_UA: {
      tel: "tel:+380636205050",
      tel_title: "+380636205050",
    },
    lt_LT: {
      tel: "tel:+37067285071",
      tel_title: "+37067285071",
    },
    en_LT: {
      tel: "tel:+37067285071",
      tel_title: "+37067285071",
    },
    en: {
      tel: "tel:+35799247785",
      tel_title: "+35799247785",
    },
  },
  instruction: {
    en_ZZ: "https://www.allsports.by/faq_partner",
    ru_BY: "https://www.allsports.by/faq_partner",
    en_BY: "https://www.allsports.by/faq_partner",
    hy_AM: "https://www.allsports.by/faq_partner",
    ru_RU: "https://www.allsports.by/faq_partner",
    en_UA: "https://www.allsports.by/faq_partner",
    uk_UA: "https://www.allsports.by/faq_partner",
    lt_LT: "https://sportbenefit.eu/lt_en/faq_partner/",
    en_LT: "https://www.allsports.by/faq_partner",
    en: "https://sportbenefit.eu/cy_en/faq_partner/",
  },
  locale: "en_ZZ",
  locales: {
    en_ZZ: "No Country",
    en_BY: "BY English",
    ru_RU: "Россия",
    ru_BY: "Беларусь",
    hy_AM: "Армения",
    en_UA: "UA English",
    uk_UA: "Украина",
    lt_LT: "Lietuva",
    en_LT: "Lithuania",
    en: "Cyprus",
  },
  app_update_request: false,
  details: {
    position: "technical manager",
    supplier: null,
    version: "",
  },
  coords: { latitude: 0.0, longitude: 0.0 },

  supportPhone: "",
};

const localeByDomain = () => {
  let locale = "uk_UA";
  const host =
    document.location && document.location.host
      ? document.location.host
      : "localhost";
  if (host.indexOf("allsports.fit") !== -1) {
    locale = "ru_RU";
  } else if (host.indexOf("allsports.am") !== -1) {
    locale = "hy_AM";
  } else if (host.indexOf("allsports.by") !== -1) {
    locale = "ru_BY";
  } else if (host.indexOf("sportbenefit.eu") !== -1) {
    locale = "en_LT";
  }
  return locale;
};

// getters
const getters = {
  getCoords(state) {
    if (state.coords.latitude && state.coords.longitude) {
      return state.coords;
    }

    const coordsStr = localStorage.getItem(SET_COORDS);
    if (!coordsStr) {
      return state.coords;
    }
    const coordsArr = coordsStr.split(":");
    let lat, lng;
    if (
      !(lat = parseFloat(coordsArr[0])) ||
      !(lng = parseFloat(coordsArr[1]))
    ) {
      return state.coords;
    }
    return { latitude: lat, longitude: lng };
  },
  support(state) {
    return state.supports[getters.locale(state)];
  },
  supportPhone(state) {
    return state.supportPhone || getters.support(state);
  },
  support_email(state) {
    return state.supportEmails[getters.locale(state)];
  },
  locale(state) {
    return state.locale || state.details.supplier.locale || localeByDomain();
  },
  instruction(state) {
    return state.instruction[state.locale];
  },
  locales(state) {
    let locale;
    if (
      state.details &&
      state.details.supplier &&
      state.details.supplier.locale
    ) {
      locale = state.details.supplier.locale;
    } else {
      locale = getters.locale(state);
    }
    const localeArray = locale.split("_");
    const foundPosition = locale.indexOf(localeArray[1]);
    const locales = state.locales;
    const result = {};
    for (let key in locales) {
      if (key.indexOf(localeArray[1]) === foundPosition) {
        result[key] = locales[key];
      }
    }
    return result;
  },
  loggedin(state) {
    return !!state.auth.refresh_token && !!state.details.supplier;
  },
  getSupplier(state) {
    return state.details.supplier;
  },
  getSupplierId(state) {
    return state.details.supplier && state.details.supplier.id;
  },
  getSupplierName(state) {
    return state.details.supplier && state.details.supplier.name;
  },
  getAttractionsByScannedCards(state) {
    if (state.details.supplier && state.details.supplier.attractions) {
      return state.details.supplier.attractions;
    }
    return [];
  },
  getIsFinancePosition(state) {
    return state.details.position === "finance";
  },
  getRedirectName(state) {
    return state.details.position === "finance"
      ? "index_accepted"
      : "new_registration";
  },
  appHasNewVersion(state) {
    return state.app_update_request;
  },
  getRefreshToken(state) {
    return state.auth.refresh_token;
  },
  getAccessToken(state) {
    return state.auth.access_token;
  },
};

// actions
const actions = {
  setGeolocation({ commit }, coords) {
    commit(SET_COORDS, coords);
  },
  setLanguage({ commit }, locale) {
    commit(SET_LANGUAGE, locale);
  },
  async signin({ commit }, input) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await apiSignin.signin({
        email: input.email,
        password: input.password,
      });
      commit(LOGIN, response.data);
      return getters.getRedirectName(state);
    } catch (error) {
      commit("error/set_error", error);
      return "";
    }
  },
  updateApp({ commit }) {
    commit(SET_IF_UPDATE_FOUND, true);
  },
  async logout({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await apiSignin.logout();
      commit(LOGOUT);
      return response.data;
    } catch (error) {
      commit(LOGOUT);
    }
  },
  refreshLogin({ commit, state }, err) {
    return new Promise((resolve, reject) => {
      return apiSignin.refreshLogin(getters.getRefreshToken(state)).then(
        (response) => {
          commit("setRefreshToken", response.data);
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization =
            "Bearer " + getters.getAccessToken(state);
          resolve(axios(err.config));
        },
        (error) => {
          commit(SET_IF_LOGOUT_FOUND, error.response);
          reject(error);
        }
      );
    });
  },
};

function lsTest() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

// mutations
const mutations = {
  [SET_COORDS](state, coords) {
    state.coords = coords;
    localStorage.setItem(SET_COORDS, coords.latitude + ":" + coords.longitude);
  },
  [SET_LANGUAGE](state, locale) {
    state.locale = locale;
    i18n.global.locale = locale.replace("_", "-");
    apiSignin.set_header("X-localization", locale);
  },
  [SET_IF_UPDATE_FOUND](state, response) {
    if (response && response.headers) {
      const prevVersion = localStorage.getItem("x-supplier-version");
      const version = response.headers["x-supplier-version"];
      if (version && version !== prevVersion) {
        if (lsTest() !== true) {
          return Sentry.captureException(
            new Error("localStorage is not working")
          );
        }
        localStorage.setItem("x-supplier-version", version);
        state.app_update_request = true;
      }
    }
  },
  [SET_IF_LOGOUT_FOUND](state, response) {
    if (response && response.status > 400) {
      state.auth.refresh_token = null;
    }
  },
  [INIT_AUTH](state) {
    state.details.version = packageJson.version;
    const locale = getters.locale(state);
    mutations[SET_LANGUAGE](state, locale);
    // Check if the ID exists
    if (localStorage.getItem("store_auth_details")) {
      try {
        let oldState = JSON.parse(localStorage.getItem("store_auth_details"));
        const oldLocale = getters.locale(oldState);
        if (
          [
            "ru_BY",
            "hy_AM",
            "ru_RU",
            "en_BY",
            "uk_UA",
            "en_UA",
            "lt_LT",
            "en_LT",
          ].indexOf(oldLocale) !== -1
        ) {
          mutations[SET_LANGUAGE](state, oldLocale);
        }
        // Check the version stored against current. If different, don't
        // load the cached version
        if (state.details.version === packageJson.version) {
          if (oldState.auth) {
            state.auth = oldState.auth;
            apiSignin.set_header(
              "Authorization",
              "Bearer " + oldState.auth.access_token
            );
          }
          if (oldState.details) {
            state.details = oldState.details;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  setRefreshToken(state, tokenData) {
    // sometimes tokens are in snake case and other times they are in camelcase :/
    const now = new Date();
    const tokensExpiry = now.setTime(
      now.getTime() + 1000 * tokenData.expires_in
    );
    apiSignin.set_header("Authorization", "Bearer " + tokenData.access_token);
    state.auth = {
      access_token: tokenData.access_token,
      refresh_token: tokenData.refresh_token,
      expires_in: tokensExpiry,
    };
  },
  [LOGIN](state, tokenData) {
    mutations.setRefreshToken(state, tokenData);
    state.details.position = tokenData.position;
    state.details.supplier = tokenData.supplier;
    Sentry.setUser({
      id: tokenData.supplier.id,
      username: tokenData.supplier.name,
    });
    const locale =
      state.details.supplier && state.details.supplier.locale
        ? state.details.supplier.locale
        : null;
    if (locale) {
      mutations[SET_LANGUAGE](state, locale);
    }
  },
  setAttractions(state, attractions) {
    state.details.supplier.attractions = attractions;
  },
  [LOGOUT](state) {
    apiSignin.set_header("Authorization", "");
    state.auth = {
      access_token: "",
      refresh_token: "",
      expires_in: "",
    };
  },
  [AUTH_MUTATION_SET_SUPPORT_PHONE](state, supportPhone) {
    state.supportPhone = supportPhone;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
