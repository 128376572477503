<template>
  <AppWithoutLoginLayout>
    <div class="update-instructions">
      <h2>{{ $t("update_instructions.title") }}</h2>
      <ol v-if="userAgentData">
        <li>
          <span>{{ $t("update_instructions.step1") }}</span>
          <p>
            <b>{{ browserName }}</b>
            <span class="icon-wrapper">
              <BaseIcon :icon="browserIcon" />
            </span>
          </p>
        </li>
        <li>{{ $t("update_instructions.step2") }}</li>
        <li>
          <i18n-t keypath="update_instructions_step_3" tag="p">
            <template v-slot:icon1>
              <span v-if="icon1" class="icon-wrapper">
                <BaseIcon :icon="icon1" />
              </span>
            </template>

            <template v-slot:text1>
              <b>{{ $t(`${text_1}`) }}</b>
            </template>

            <template v-slot:text2>
              {{ $t(`${text_2}`) }}
            </template>

            <template v-slot:icon2>
              <span v-if="icon2" class="icon-wrapper">
                <BaseIcon :icon="icon2" />
              </span>
            </template>

            <template v-slot:text3>
              {{ $t(`${text_3}`) }}
            </template>
          </i18n-t>
        </li>
        <li v-if="isDesktop">
          {{ $t(`update_instructions.step4`) }}
        </li>
      </ol>
      <img
        v-show="imageSrc"
        ref="instructionsImage"
        :src="imageSrc"
        alt="instructions"
      />

      <div class="update-instructions__controls">
        <a
          href="https://partner.allsports.fit/"
          class="update-instructions__link"
          target="_blank"
          >{{ $t("app.new_version") }}</a
        >
        <ClipboardCopyButton value="https://partner.allsports.fit/">{{
          $t("app.copy_link")
        }}</ClipboardCopyButton>
      </div>
    </div>
  </AppWithoutLoginLayout>
</template>
<style lang="scss">
.update-instructions {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #03124b;
  display: flex;
  flex-direction: column;
  align-items: center;

  ol > li {
    font-size: 24px;
    text-align: start;

    &:not(:first-child) {
      margin-top: 16px;
    }

    p {
      margin: 0;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__controls {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__link {
    text-decoration: none;
    outline: none;
    padding: 12px 24px;
    background-color: #2954bb;
    color: white;
    border-radius: 32px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;

    &:hover {
      background-color: #2348a3;
    }
  }

  .icon-wrapper {
    display: inline-block;
    margin: 0 8px;
  }
}
</style>
<script>
import AppWithoutLoginLayout from "@/layouts/AppWithoutLoginLayout.vue";
import BaseIcon from "@/components/icons/BaseIcon.vue";
import ClipboardCopyButton from "@/components/buttons/ClipboardCopyButton.vue";

import { DEVICE_TYPES } from "@/store/modules/browser";
import { mapGetters, mapState } from "vuex";

const IMAGES = {
  chrome: require("@/assets/img/chrome_instructions.webp"),
  edge: require("@/assets/img/edge_instructions.webp"),
  mbChrome: require("@/assets/img/mb_chrome_instructions.webp"),
  mbEdge: require("@/assets/img/mb_edge_instructions.webp"),
  mbFirefox: require("@/assets/img/mb_firefox_instructions.webp"),
  mbSafari: require("@/assets/img/mb_safari_instructions.webp"),
};

const INSTRUCTIONS = {
  [DEVICE_TYPES.desktop]: {
    chrome: {
      step3: {
        icon1: "chrome-install",
        icon2: "",
        text_1: "update_instructions.desktop_text_1",
        image: IMAGES.chrome,
      },
    },
    edge: {
      step3: {
        icon1: "edge-install",
        icon2: "",
        text_1: "update_instructions.desktop_text_1",
        image: IMAGES.edge,
      },
    },
  },
  [DEVICE_TYPES.mobile]: {
    chrome: {
      step3: {
        icon1: "mobile-install",
        icon2: "menu",
        text_1: "update_instructions.mb_chrome_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.mb_text_3",
        image: IMAGES.mbChrome,
      },
    },
    "mobile chrome": {
      step3: {
        icon1: "mobile-install",
        icon2: "menu",
        text_1: "update_instructions.mb_chrome_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.mb_text_3",
        image: IMAGES.mbChrome,
      },
    },
    edge: {
      step3: {
        icon1: "mobile-install",
        icon2: "menu",
        text_1: "update_instructions.mb_edge_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.mb_text_3",
        image: IMAGES.mbEdge,
      },
    },
    "mobile firefox": {
      step3: {
        icon1: "mobile-install",
        icon2: "menu",
        text_1: "update_instructions.mb_firefox_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.mb_text_3",
        image: IMAGES.mbFirefox,
      },
    },
    safari: {
      step3: {
        icon1: "safari-install",
        icon2: "safari-menu",
        text_1: "update_instructions.safari_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.safari_text_3",
        image: IMAGES.mbSafari,
      },
    },
    "mobile safari": {
      step3: {
        icon1: "safari-install",
        icon2: "safari-menu",
        text_1: "update_instructions.safari_text_1",
        text_2: "update_instructions.text_2",
        text_3: "update_instructions.safari_text_3",
        image: IMAGES.mbSafari,
      },
    },
  },
};

export default {
  name: "AppUpdateInstructions",
  data() {
    return {
      instructions: INSTRUCTIONS,
    };
  },
  components: {
    AppWithoutLoginLayout,
    BaseIcon,
    ClipboardCopyButton,
  },
  computed: {
    ...mapState("browser", {
      browsers: (state) => state.browsers,
    }),
    ...mapGetters({
      userAgentData: "browser/userAgentData",
      deviceType: "browser/deviceType",
      isDesktop: "browser/desktop",
      browserName: "browser/browserName",
    }),
    updateStep3() {
      const deviceType = this.deviceType;
      const browserName = this.browserName;

      return this.instructions[deviceType][browserName].step3;
    },
    icon1() {
      return this.updateStep3?.icon1 || "";
    },
    icon2() {
      return this.updateStep3?.icon2 || "";
    },
    text_1: function () {
      return this.updateStep3?.text_1 || "";
    },
    text_2() {
      return this.updateStep3?.text_2 || "";
    },
    text_3() {
      return this.updateStep3?.text_3 || "";
    },
    browserIcon() {
      return this.browsers[this.browserName].icon;
    },
    imageSrc() {
      return this.updateStep3?.image || IMAGES.mbChrome;
    },
  },
};
</script>
