import store from "@/store";
import { SET_IF_UPDATE_FOUND } from "@/store/mutation-types";

/**
 * Created by Alexander Golovnya on 2019-05-04.
 */
export function AxiosInterceptorOnFullFilled(response) {
  store.commit("auth/" + SET_IF_UPDATE_FOUND, response);
  return response;
}

export function AxiosInterceptorOnRejected(error) {
  if (
    error &&
    ["Request failed with status code 404", "Network Error"].indexOf(
      error.message
    ) !== -1
  ) {
    error = {
      message: `Данная версия панели поставщика больше не поддерживается! Для перехода на новую версию, пожалуйста, следуйте инструкции <a href="https://supplier.allsports.by/app_update">https://supplier.allsports.by/app_update</a>  либо обратитесь в нашу техподдержку:  <a href="tel:+375447709426">+375 44 7709426</a>`,
    };
  }
  store.commit("auth/" + SET_IF_UPDATE_FOUND, error.response);
  let res = error.response;
  if (
    store.getters["auth/getRefreshToken"] &&
    res &&
    res.status === 401 &&
    res.config &&
    !res.config.__isRetryRequest
  ) {
    return store.dispatch("auth/refreshLogin", error);
  }
  store.commit("error/set_error", error);
  throw error;
}
